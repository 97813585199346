import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import i18n from "../../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title, TitleText, Row } from "./schedulersSearch.styles";
import Table from "../../../SivanPlusTable/SivanPlusTable";
import { getSchedulers } from "../../../../actions/adminActions";
import { updateEventStatus, eventTypes, eventStatusTypes } from "../../../../actions/schedulerActions";
import { deleteSchedulers } from "../../../../actions/adminActions";
import QuestionModal from "../../../Modal/QuestionModal";
import { toast } from "react-toastify";
import { DateColumnFilter, SelectColumnFilterCustomValue } from "../../../SivanPlusTable/filters";
import moment from "moment";
import Toggle from "react-toggle";
import Button from "../../../UiComponents/Buttons/Default";
import { documentTypes } from "../../../../constants/incomes";
import Checkbox from "@material-ui/core/Checkbox";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const getNameStyle = (studentData) => {
  let styles = {};
  if (studentData.internalTestStatus) {
    styles.borderTop = "1px solid black";
  }
  if (
    studentData.conversion ||
    studentData.refresh ||
    studentData.renewal ||
    studentData.control
  ) {
    styles.borderLeft = "1px solid black";
    styles.borderRight = "1px solid black";
  }
  if (
    studentData.fundingBody !== 999 &&
    studentData.fundingBody !== null &&
    typeof studentData.fundingBody !== "undefined"
  ) {
    styles.borderBottom = "1px solid black";
  }

  return styles;
};
const shColumns = [
  {
    Header: 'start',
    accessor: "start",
    Filter: DateColumnFilter,
    filter: (array, s, date) => {
      return array.filter((x) => moment(x.original.start).isSame(date, "date"));
    },
    Cell: ({ row: { original } }) => {
      const date = moment(original.start).format("DD/MM/YYYY HH:MM");
      return date;
    },
  },
  {
    Header: 'end',
    accessor: "end",
    Filter: DateColumnFilter,
    filter: (array, s, date) => {
      if (!original.end) return "-";
      return array.filter((x) => moment(x.original.end).isSame(date, "date"));
    },
    Cell: ({ row: { original } }) => {
      if (!original.start) return "-";
      const date = moment(original.start).format("DD/MM/YYYY HH:MM");
      return date;
    },
  },
  {
    Header: 'eventType',
    accessor: "eventType",
    Filter: SelectColumnFilterCustomValue(Object.keys(eventTypes).map((k) => ({value: k, title: eventTypes[k]}))),
    filter: (array, s, value) => {
      return array.filter((x) => value == 0 || x.original.eventType == value);
    },
    Cell: ({ row: { original } }) => (
      <span
        style={{
          ...getNameStyle(original),
        }}
      >
        {eventTypes[original.eventType]}
      </span>
    ),
  },
  {
    Header: 'eventStatus',
    accessor: "eventStatus",
    Filter: SelectColumnFilterCustomValue(Object.keys(eventStatusTypes).map((k) => ({value: k, title: eventStatusTypes[k]}))),
    filter: (array, s, value) => {
      return array.filter((x) => value == 0 || x.original.eventType == value);
    },
    Cell: ({ row: { original } }) => (
      <span
        style={{
          ...getNameStyle(original),
        }}
      >
        {eventStatusTypes[original.eventStatus]}
      </span>
    ),
  },
  {
    Header: "teacherUsername",
    accessor: "teacherUsername",
  },
  {
    Header: "price",
    accessor: "price",
  },
  {
    Header: dictionary.table.name,
    accessor: "firstName",
    Cell: ({ row: { original } }) => (
      <span
        style={{
          ...getNameStyle(original),
        }}
      >
        {original.firstName} {original.lastName}
      </span>
    ),
  },
  {
    Header: 'invoices',
    accessor: 'invoices',
    filter: (array, s, value) => {
      return array.filter((val) =>{
        const _i = val.original.invoices.map((v) => `№ ${v.documentNumber}; ${documentTypes[v.documentType]}; amount: ${v.amount}`).join("<br/>");
        return _i.includes(value)
      });
    },
    Cell: ({ row: { original } }) => (
      <span
        style={{
          ...getNameStyle(original),
        }}
      >
        {original.invoices.map((v) => `№ ${v.documentNumber}; ${documentTypes[v.documentType]}; amount: ${v.amount}`).join("<br/>")}
      </span>
    ),
  },
]

const SchedulersSearch = ({
  updateEventStatus,
  schoolId,
}) => {
  const { t, i18n } = useTranslation("common");
  const [list, setlist] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDeleteAllModal, setIsDeleteAllModal] = useState(false);
  const [tableColumns, setTableColumns] = useState(shColumns);
  const [selectedRows, setSelectedRows] = useState([]);

  const [filter, setFilter] = useState({
    new: 1,
    canceled: 0,
    "canceled-charged": 0,
    start: moment().set("D", 1).format("YYYY-MM-DD"),
    end: undefined,
  });

  useEffect(() => {
    fetchData();
  }, [schoolId, filter], true);

  useEffect(() => {
    console.log("selectedRows", selectedRows)
  }, [selectedRows]);

  const fetchData = async () =>  {
    setIsTableLoading(true)
    let data = [];
    // setFilter([]);
    setlist(data);
    try {
      data = await getSchedulers(schoolId, filter);
    } catch (e) {
      toast.error("כישלון במחיקת תלמיד");
      console.error(e)
    }
    setIsTableLoading(false)
    setlist(data);
  }

  useEffect(() => {
    fetchData();
    return () => {
      setlist([]);
    }; 
  }, []);

  const localDeleteAll = async () => {
    setIsLoadingDelete(true);
    setIsDeleteAllModal(false);
    try {
      const iseDeleted = await deleteSchedulers(schoolId, selectedRows.map((v) => v.id))
      if (iseDeleted) {
        toast.success("התלמיד הוסר");
        fetchData()
      } else {
        toast.error("כישלון במחיקת תלמיד");
      }
    } catch(e) {
      toast.error("כישלון במחיקת תלמיד");
      console.error(e)
    }
    setIsLoadingDelete(false);
  }

  const setCustomSelectedRows = (rows) => {
    if (rows.length != selectedRows.length) {
      setSelectedRows(rows.map((v) => v.original));
    }
  }

  const closeQuestionModal = async () => {
    setIsDeleteAllModal(false);
  }
  return (
    <Container>
      <Title style={{ marginBottom: "10px", height: "12vh" }}>
        <TitleText>
          Lessons
        </TitleText>
          <div style={{display: "flex", flexDirection: "column"}}>
            <Row className="row-select" style={{ marginBottom: "5px" }}>
              <div className="select-toggle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginRight: "10px"
                }}
              >
                <label htmlFor="cheese-status-block" style={{ margin: 0, fontSize: "12px" }}>
                  show new
                </label>
                <Toggle 
                  id="cheese-status-block"
                  defaultChecked={filter.new == 1 ? true : false}
                  onChange={ () => {
                    filter.new = filter.new == 1 ? 0 : 1
                    setFilter({...filter})
                  }
                  }
                />
              </div>
              <div className="select-toggle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginRight: "10px"
                }}
              >
                <label htmlFor="cheese-status-block" style={{ margin: 0, fontSize: "12px" }}>
                  show canceled
                </label>
                <Toggle 
                  id="cheese-status-block"
                  defaultChecked={filter.canceled == 1 ? true : false}
                  onChange={ () => {
                    filter.canceled = filter.canceled == 1 ? 0 : 1
                    setFilter({...filter})
                  }
                  }
                />
              </div>
              <div className="select-toggle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginRight: "10px"
                }}
              >
                <label htmlFor="cheese-status-block" style={{ margin: 0, fontSize: "12px" }}>
                  show canceled and changed
                </label>
                <Toggle 
                  id="cheese-status-block"
                  defaultChecked={filter['canceled-charged'] == 1 ? true : false}
                  onChange={ () => {
                    filter['canceled-charged'] = filter['canceled-charged'] == 1 ? 0 : 1
                    setFilter({...filter})
                  }
                  }
                />
              </div>
            </Row>
            <Row className="row-select">
              {DateColumnFilter({label: "Start", column: {filterValue: filter.start, setFilter: (v) => {filter.start = moment(v).format("YYYY-MM-DD"); setFilter({...filter})}}})}
              - 
              {DateColumnFilter({label: "End",column: {filterValue: filter.end, setFilter: (v) => {filter.end = moment(v).format("YYYY-MM-DD"); setFilter({...filter})}}})}              
              <Button className="button-navigation"
                text="clear"
                disabled={!filter.end && !filter.start}
                isActive={filter.end || filter.start}
                marginRight="0.3"
                onClick={ () => {filter.end = filter.start = undefined; setFilter({...filter}) } }
              />
            </Row>
          </div>          
          <Button className="button-navigation"
            text={() => ({__html: `remove all <div style="font-size: 10px;">${selectedRows.length} records</div>`})}
            disabled={selectedRows.length===0}
            marginRight="0.3"
            isDelete={ true }
            onClick={ () => setIsDeleteAllModal(true) }
          />
      </Title>
      {isDeleteAllModal && <QuestionModal
        titleText="Do you want to remove selected lessons?"
        isDelete={true}
        isLoaded={isLoadingDelete}
        deleteItem={localDeleteAll}
        modalIsOpen={isDeleteAllModal}
        closeModal={closeQuestionModal}
      />}
      <Table
        data={list}
        componentColumns={tableColumns}
        // onRowClick={onRowClick}
        isLoading={isTableLoading}
        checkedRows={setCustomSelectedRows}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isLoggedIn: state.session.isLoggedIn,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateEventStatus,
  })(SchedulersSearch)
);
