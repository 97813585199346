import React, { useState, useEffect } from "react";
import i18n from "../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title, NavAndBackContainer } from "./control.styles";
// import NewClient from "./NewClient/NewClient";
// import ClientData from "./ClientData/ClientData";
import { getSchools } from "../../actions/adminActions";
import StudentSearch from "./Schools/Students/StudentSearch";
import SchedulersSearch from "./Schools/Schedulers/SchedulersSearch";
import Navbar from "../Navbar/Navbar";
import Select from "react-select";
import ClientsSearch from "./Schools/Clients/ClientsSearch";
import FundingBodiesSearch from "./Schools/FundingBodies/FundingBodiesSearch";
import ChangeSchool from "./ChangeSchool";
import InvoicesSearch from "./Schools/Invoices/InvoicesSearch";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const adminCustomSchoolStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "300px",
    minWidth: 150,
    fontSize: "12px"
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    paddingRight: "0.5vw",
  }),
};

const AdminControl = ({ history }) => {
  const [isStudentModalOpen, setStudentModalOpen] = useState(false);
  const [isSchedulerModalOpen, setSchedulerModalOpen] = useState(false);
  const [schools, setSchools] = useState([]);
  const [isActive, setIsActive] = useState(1);
  const [viewSchool, setViewSchool] = useState(null);
  const [menuItems, setMenuItems] = useState([
    dictionary.admin.tabStudets,
    dictionary.admin.tabLessons,
    dictionary.admin.tabClients,
    dictionary.admin.tabFundingBodies,
    dictionary.admin.tabInvoices,
  ]);
  const [currentOpenedView, setCurrentOpenedView] = useState(dictionary.admin.tabStudets);
  
  useEffect(() => {
    async function fetchData() {
      const result = await getSchools(isActive);

      setSchools(result.map((v) => ({label: `#${v.id} - ${v.name}`, value: v})))
    }
    fetchData();
  }, []);

  const changeSchool = (school) => {
    setViewSchool(school)
  }

  return (
    <Container>
      <Title>
        Control panel
        <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'end',
        alignItems: 'center',
      }}>
        <ChangeSchool changeSchool={changeSchool} />
      </div>
      </Title>
      <NavAndBackContainer>
        <div />
        <Navbar
          menuOptions={menuItems}
          currentView={currentOpenedView}
          setCurrentOpenedView={setCurrentOpenedView}
        />
        <i
          className="fa fa-arrow-right"
          aria-hidden="true"
          style={{ cursor: "pointer" }}
          onClick={() => history.goBack()}
        />
      </NavAndBackContainer>
      {viewSchool && currentOpenedView == dictionary.admin.tabStudets && <StudentSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabLessons && <SchedulersSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabClients && <ClientsSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabFundingBodies && <FundingBodiesSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabInvoices && <InvoicesSearch
        schoolId={viewSchool.value.id}
      />}
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps, {})(AdminControl);
