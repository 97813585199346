import moment from "moment";
import pdfApi from "../apis/pdf";

export const generateIncomePdf = (incomeId, original=0, emails='') => async (dispatch, getState) => {
  try {
    const params = { original };
    if (emails) params.emails = emails;
    const response = await pdfApi.get(`/generate-pdf/${incomeId}`, { params, responseType: "blob" });
    if (!response.headers['content-type'].includes('application/pdf')) return;
    // const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });
    return file;
  } catch (error){
    console.error(error);
    return null;
  }
}

export const generateIncomeFBPdf = (data) => async (dispatch, getState) => {
  try {
    const response = await pdfApi.post(`/generate-pdf/fb-invoice`, data);
    if (!response.headers['content-type'].includes('application/pdf')) return;
    const file = new Blob([response.data], { type: "application/pdf" });
    return file;
  } catch (error){
    console.error(error);
    return null;
  }
}

export const generateInvoicePdf = (data) => async (dispatch, getState) => {
  try {
    const response = await pdfApi.post(`/generate-pdf/invoice`, data);
    if (!response.headers['content-type'].includes('application/pdf')) return;
    const file = new Blob([response.data], { type: "application/pdf" });
    return file;
  } catch (error){
    console.error(error);
    return null;
  }
}

export const downloadFile = (file) => {
  if (!file) {
    console.log('file not found');
    return;
  }
  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  const pdfWindow = window.open();
  pdfWindow.location.href = fileURL;
}
